import * as Types from '../../../graphql/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ChannelTalkButtonQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ChannelTalkButtonQuery = { __typename?: 'Query', me: { __typename?: 'User', id: string, channelTalkMemberHash: string, name: string, email: string, company: { __typename?: 'Company', id: number, name: string } } };


export const ChannelTalkButtonDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"ChannelTalkButton"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"me"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"channelTalkMemberHash"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"email"}},{"kind":"Field","name":{"kind":"Name","value":"company"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}}]}}]}}]}}]} as unknown as DocumentNode;

/**
 * __useChannelTalkButtonQuery__
 *
 * To run a query within a React component, call `useChannelTalkButtonQuery` and pass it any options that fit your needs.
 * When your component renders, `useChannelTalkButtonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChannelTalkButtonQuery({
 *   variables: {
 *   },
 * });
 */
export function useChannelTalkButtonQuery(baseOptions?: Apollo.QueryHookOptions<ChannelTalkButtonQuery, ChannelTalkButtonQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ChannelTalkButtonQuery, ChannelTalkButtonQueryVariables>(ChannelTalkButtonDocument, options);
      }
export function useChannelTalkButtonLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ChannelTalkButtonQuery, ChannelTalkButtonQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ChannelTalkButtonQuery, ChannelTalkButtonQueryVariables>(ChannelTalkButtonDocument, options);
        }
export function useChannelTalkButtonSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ChannelTalkButtonQuery, ChannelTalkButtonQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ChannelTalkButtonQuery, ChannelTalkButtonQueryVariables>(ChannelTalkButtonDocument, options);
        }
export type ChannelTalkButtonQueryHookResult = ReturnType<typeof useChannelTalkButtonQuery>;
export type ChannelTalkButtonLazyQueryHookResult = ReturnType<typeof useChannelTalkButtonLazyQuery>;
export type ChannelTalkButtonSuspenseQueryHookResult = ReturnType<typeof useChannelTalkButtonSuspenseQuery>;
export type ChannelTalkButtonQueryResult = Apollo.QueryResult<ChannelTalkButtonQuery, ChannelTalkButtonQueryVariables>;
import * as Types from '../../../graphql/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TopbarQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type TopbarQuery = { __typename?: 'Query', me: { __typename?: 'User', id: string, newNotificationCount: number } };


export const TopbarDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"Topbar"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"me"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"newNotificationCount"}}]}}]}}]} as unknown as DocumentNode;

/**
 * __useTopbarQuery__
 *
 * To run a query within a React component, call `useTopbarQuery` and pass it any options that fit your needs.
 * When your component renders, `useTopbarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTopbarQuery({
 *   variables: {
 *   },
 * });
 */
export function useTopbarQuery(baseOptions?: Apollo.QueryHookOptions<TopbarQuery, TopbarQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TopbarQuery, TopbarQueryVariables>(TopbarDocument, options);
      }
export function useTopbarLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TopbarQuery, TopbarQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TopbarQuery, TopbarQueryVariables>(TopbarDocument, options);
        }
export function useTopbarSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<TopbarQuery, TopbarQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TopbarQuery, TopbarQueryVariables>(TopbarDocument, options);
        }
export type TopbarQueryHookResult = ReturnType<typeof useTopbarQuery>;
export type TopbarLazyQueryHookResult = ReturnType<typeof useTopbarLazyQuery>;
export type TopbarSuspenseQueryHookResult = ReturnType<typeof useTopbarSuspenseQuery>;
export type TopbarQueryResult = Apollo.QueryResult<TopbarQuery, TopbarQueryVariables>;